import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"
import { Display } from "./typography"
import PageTitle from "./pageTitle"
import ResetPasswordForm from "./resetPasswordForm"

const ResetPassword = () => {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Col md={12}>
            <PageTitle>
              <Display>Reimposta password</Display>
            </PageTitle>
          </Col>
        </Row>
        <Row justify="center">
          <Col sm={12} md={9} lg={6}>
            <ResetPasswordForm />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 6rem;
  margin-bottom: 6rem;
`

export default ResetPassword
