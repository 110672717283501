import { useStaticQuery, graphql } from "gatsby"

export const useLocations = () => {
  const subjects = useStaticQuery(graphql`
    query HookLocationQuery {
      allDatoCmsLocation(
        filter: { root: { eq: false } }
        sort: { fields: name }
      ) {
        nodes {
          originalId
          name
        }
      }
    }
  `)
  return subjects.allDatoCmsLocation.nodes
}
