import { useStaticQuery, graphql } from "gatsby"

export const useSubjects = () => {
  const subjects = useStaticQuery(graphql`
    query HookSubjectQuery {
      allDatoCmsSubject(sort: { fields: [position], order: [ASC] }) {
        nodes {
          name
          slug
          originalId
          position
          area {
            name
          }
        }
      }
    }
  `)
  return subjects.allDatoCmsSubject.nodes
}
