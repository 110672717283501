import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"
import { Display } from "./typography"
import PageTitle from "./pageTitle"
import SignInForm from "./signInForm"
import { Link } from "gatsby"
import { Box } from "theme-ui"

const SignIn = ({ location }) => {
  return (
    <Wrapper>
      <Box
        sx={{
          padding: [3, 3],
          backgroundColor: "#ffdddd",
          borderBottom: "1px solid #f44336",
          color: "#f44336",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Area riservata ai soli docenti.
      </Box>
      <Container>
        <Row>
          <Col md={12}>
            <PageTitle>
              <Display>Accedi</Display>
              <p>
                Non hai un account?{" "}
                <Link
                  to="/area-docenti/registrati/"
                  state={{ book: location.state ? location.state.book : null }}
                >
                  Registrati
                </Link>
              </p>
            </PageTitle>
          </Col>
        </Row>
        <Row justify="center">
          <Col sm={12} md={9} lg={6}>
            <Box
              sx={{
                padding: [2, 3],
                backgroundColor: "#fafafa",
                fontSize: [1],
                p: {
                  mb: [2],
                  ":last-of-type": {
                    mb: [0],
                  },
                },
              }}
            >
              <p>
                Per navigare nel sito e fruire dei contenuti liberi non è
                necessaria alcuna registrazione.
              </p>
              <p>
                Solo i docenti possono registrarsi per ottenere materiali
                didattici aggiuntivi.
              </p>
              <p>
                Il materiale per studenti è, invece, direttamente integrato nei
                libri digitali disponibili su bSmart.it
              </p>
            </Box>
            <SignInForm book={location.state ? location.state.book : null} />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 0rem;
  margin-bottom: 6rem;
`

export default SignIn
