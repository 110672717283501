import React, { useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import auth, { db } from "../services/firebase"
import { Body, CaptionSmall } from "./typography"
import { useSubjects } from "../hooks/useSubjects"
import { useLocations } from "../hooks/useLocations"
import Select from "react-select"
import { Text } from "theme-ui"

const SignUpForm = ({ book }) => {
  const subjects = useSubjects().map(subject => ({
    value: subject.originalId,
    label: subject.name,
  }))

  const locations = useLocations().map(location => ({
    value: location.originalId,
    label: location.name,
  }))

  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [lastName, setLastName] = useState("")
  const [mobile, setMobile] = useState("")
  const [subject, setSubject] = useState("")
  const [location, setLocation] = useState({
    label: "Seleziona la provincia",
    value: "",
  })
  const [school, setSchool] = useState("")
  const [city, setCity] = useState("")
  const [password, setPassword] = useState("")
  const [newsletter, setNewsletter] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    const data = {
      name: name,
      lastName: lastName,
      mobile: mobile,
      school: school,
      city: city,
      subject: subject,
      location: location.label,
      locationId: location.value,
      email: email,
      newsletter: newsletter,
      isVerified: false,
      createdAt: Date.now(),
    }
    setLoading(true)
    auth
      .createUserWithEmailAndPassword(email, password)
      .then(user => {
        // Signed in
        // ...
        db.collection("users")
          .doc()
          .set(data)
          .then(user => {
            console.log(user, "SEND EMAIL")
            axios
              .post("/.netlify/functions/sendSignUpEmail", data)
              .then(function (response) {
                console.log(response)
              })
              .catch(function (error) {
                console.log(error)
              })
            navigate(`/registrazione-confermata/`)
          })
          .catch(error => {
            console.log(error)
            setLoading(false)
            // console.log(errorCode, errorMessage)
            // ..
          })
      })
      .catch(error => {
        setLoading(false)
        if (error.code === "auth/email-already-in-use") {
          setErrorMessage("L'email è già stata utilizzata da un altro utente")
        }
        // console.log(error)
        // ..
      })
  }

  return (
    <Wrapper id="form">
      <form method="post" name="sign-up-form" onSubmit={handleSubmit}>
        <Text
          as="h3"
          sx={{
            textTransform: "uppercase",
            color: "#282c72",
          }}
        >
          Dati docente
        </Text>
        <section>
          <label htmlFor="name">Nome *</label>
          <input
            onChange={e => setName(e.target.value)}
            type="name"
            name="name"
            id="name"
            value={name}
            required
          />
        </section>
        <section>
          <label htmlFor="last-name">Cognome *</label>
          <input
            onChange={e => setLastName(e.target.value)}
            type="last-name"
            name="last-name"
            id="last-name"
            value={lastName}
            required
          />
        </section>
        <section>
          <label htmlFor="mobile">Cellulare (facoltativo)</label>
          <input
            onChange={e => setMobile(e.target.value)}
            type="mobile"
            name="mobile"
            id="mobile"
            value={mobile}
          />
        </section>
        <section>
          <label htmlFor="email">Email *</label>
          <input
            onChange={e => setEmail(e.target.value)}
            type="email"
            name="email"
            id="email"
            value={email}
            required
          />
          <CaptionSmall>
            Inserire l'indirizzo e-mail istituzionale per accelerare il processo
            di verifica
          </CaptionSmall>
        </section>
        <section>
          <label htmlFor="password">Password *</label>
          <input
            onChange={e => setPassword(e.target.value)}
            type="password"
            name="password"
            id="password"
            value={password}
            required
          />
          <CaptionSmall>Almeno 6 caratteri</CaptionSmall>
        </section>
        <section>
          <label htmlFor="subject">Materia di interesse *</label>
          <Select
            placeholder="Seleziona la materia"
            options={subjects}
            onChange={e => setSubject(e.label)}
            required
          />
        </section>
        <Text
          as="h3"
          sx={{
            mt: [3, 3, 4],
            textTransform: "uppercase",
            color: "#282c72",
          }}
        >
          Dati scuola
        </Text>
        <section>
          <label htmlFor="school">Nome Scuola *</label>
          <input
            onChange={e => setSchool(e.target.value)}
            type="school"
            name="school"
            id="school"
            value={school}
            required
          />
        </section>
        <section>
          <label htmlFor="city">Città Scuola *</label>
          <input
            onChange={e => setCity(e.target.value)}
            type="city"
            name="city"
            id="city"
            value={city}
            required
          />
        </section>
        <section>
          <label htmlFor="location">Provincia Scuola *</label>
          <Select
            placeholder="Seleziona Provincia"
            options={locations}
            defaultValue={{ label: "Seleziona Provincia", value: "" }}
            onChange={e => {
              setLocation(e)
            }}
            required
          />
        </section>
        <section>
          <p>
            Dopo aver preso visione della presente informativa sulla privacy ai
            sensi dell'art. 13 del Regolamento (UE) 2016/679 (c.d. GDPR),
            acconsento al trattamento dei dati personali al fine esclusivo di
            avere informazioni riguardo i servizi relativi al presente sito web.
            Per completare la registrazione è necessaria l'autorizzazione al
            trattamento dei dati personali.
          </p>
        </section>
        <Checkbox>
          <input type="checkbox" id="privacy" name="privacy" required />
          <label htmlFor="privacy">Si, acconsento</label>
        </Checkbox>
        <Checkbox>
          <input
            type="checkbox"
            id="newsletter"
            name="newsletter"
            defaultChecked={newsletter}
            onChange={e => setNewsletter(e.target.checked)}
          />
          <label htmlFor="newsletter">
            Si, desidero ricevere le ultime novità tramite e-mail
          </label>
        </Checkbox>

        <section>
          <input type="submit" value={loading ? `Caricamento...` : `Invia`} />
        </section>
        {errorMessage && <Body color="red">{errorMessage}</Body>}
      </form>
    </Wrapper>
  )
}

export default SignUpForm

const Checkbox = styled.div`
  display: flex;
  flex-direction: row;
  input {
    margin-right: 0.5rem;
  }
  label {
    font-size: 14px;
    line-height: 1.1;
    margin-bottom: 0 !important;
  }
`

const Wrapper = styled.div`
  border-radius: ${DesignTokens.borderRadius};
  margin-bottom: 6rem;
  form {
    padding-top: 2rem;
    label {
      margin-bottom: 0.25rem;
      color: black;
      font-size: 14px;
    }
    input,
    textarea {
      padding: ${DesignTokens.spacing[3]};
      color: black;
      border: none;
      font-size: 16px;
      background: transparent;
      border-bottom: 1px solid ${DesignTokens.colors.primary[400]};
      &:hover,
      &:focus {
        border-bottom: 1px solid ${DesignTokens.colors.tertiary[500]};
      }
    }

    input[type="submit"] {
      background: ${DesignTokens.colors.primary[500]};
      border: none;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      cursor: pointer;
      &:hover,
      &:active {
        background: ${DesignTokens.colors.tertiary[500]};
      }
      &:disabled {
        background: #4caf50;
        cursor: default;
      }
    }
    section {
      display: flex;
      flex-direction: column;
      p {
        margin-top: 6px;
        font-size: 14px;
        color: ${DesignTokens.colors.primary[200]};
      }
    }
    & > * {
      margin-bottom: ${DesignTokens.spacing[4]};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
