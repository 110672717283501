import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TeacherResources from "../components/teacherResources"
import SignIn from "../components/signIn"
import SignUp from "../components/signUp"
import ResetPassword from "../components/resetPassword"

const TeachersResourcesPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Router basepath={`/area-docenti`}>
        <TeacherResources path="/risorse-per-docenti" />
        <SignUp path="/registrati" />
        <SignIn path="/accedi" />
        <ResetPassword path="/reset-password" />
      </Router>
    </Layout>
  )
}

export default TeachersResourcesPage
