import React, { useContext } from "react"
import { Link, navigate } from "gatsby"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"
import { Heading } from "../components/typography"
import BookSidebar from "../components/bookSidebar"
import Resources from "./resources"
import Gut from "./gut"
import { UserContext } from "../context/userContext"

const TeacherResources = ({ location }) => {
  const user = useContext(UserContext, location)

  // console.log(user, location)
  if (!user.user) {
    navigate("/area-docenti/accedi/", {
      replace: true,
      state: { book: location.state ? location.state.book : null },
    })
    return null
  }

  if (user.user) {
    if (user.user.isVerified !== true) {
      navigate("/", {
        replace: true,
      })
    }
  }

  let book = null
  if (location.state) {
    book = location.state.book || null
  }

  // console.log(book)
  return (
    <Wrapper>
      <Container>
        {book && (
          <CustomRow>
            <Col md={5}>
              <BookSidebar book={book} />
            </Col>
            <Col md={7}>
              <Gut gutter={2}>
                <Heading>Risorse per docenti</Heading>
                <Resources resources={book.teachersResources} />
                <Link to={`/catalogo/${book.slug}/`}>‹ Torna al libro</Link>
              </Gut>
            </Col>
          </CustomRow>
        )}
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 6rem;
  margin-bottom: 6rem;
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const CustomRow = styled(Row)`
  @media screen and (max-width: 991px) {
    & > * {
      margin-bottom: 4rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

export default TeacherResources
