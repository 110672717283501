import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import auth from "../services/firebase"
import { Body } from "./typography"

const SignInForm = ({ book }) => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, showError] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    setLoading(true)
    showError(false)
    auth
      .signInWithEmailAndPassword(email, password)
      .then(user => {
        // Signed in
        // ...
        console.log(user)
        book
          ? navigate(`/area-docenti/risorse-per-docenti`, {
              state: { book: book },
            })
          : navigate(`/`)
      })
      .catch(error => {
        // console.log(errorCode, errorMessage)
        // ..
        setLoading(false)
        showError(true)
      })
  }

  return (
    <Wrapper id="form">
      <form method="post" name="sign-in-form" onSubmit={handleSubmit}>
        <section>
          <label htmlFor="email">Email</label>
          <input
            onChange={e => setEmail(e.target.value)}
            type="email"
            name="email"
            id="email"
            value={email}
            required
          />
        </section>
        <section>
          <label htmlFor="password">Password</label>
          <input
            onChange={e => setPassword(e.target.value)}
            type="password"
            name="password"
            id="password"
            value={password}
            required
          />
        </section>
        <section>
          <input type="submit" value={loading ? `Caricamento...` : `Invia`} />
        </section>
        {error && <Body color="red">Credenziali non valide</Body>}
        <Body>
          Password dimenticata?{" "}
          <Link to="/area-docenti/reset-password/">Reimposta password</Link>
        </Body>
      </form>
    </Wrapper>
  )
}

export default SignInForm

const Wrapper = styled.div`
  border-radius: ${DesignTokens.borderRadius};
  margin-bottom: 6rem;
  form {
    padding-top: 2rem;
    label {
      margin-bottom: 0.25rem;
      color: black;
      font-size: 14px;
    }
    input,
    textarea {
      padding: ${DesignTokens.spacing[3]};
      color: black;
      border: none;
      font-size: 16px;
      background: transparent;
      border-bottom: 1px solid ${DesignTokens.colors.primary[400]};
      &:hover,
      &:focus {
        border-bottom: 1px solid ${DesignTokens.colors.tertiary[500]};
      }
    }

    input[type="submit"] {
      background: ${DesignTokens.colors.primary[500]};
      border: none;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      cursor: pointer;
      &:hover,
      &:active {
        background: ${DesignTokens.colors.tertiary[500]};
      }
      &:disabled {
        background: #4caf50;
        cursor: default;
      }
    }
    section {
      display: flex;
      flex-direction: column;
      p {
        font-size: 12px;
        color: ${DesignTokens.colors.primary[200]};
      }
    }
    & > * {
      margin-bottom: ${DesignTokens.spacing[4]};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
