import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"
import { Display } from "./typography"
import PageTitle from "./pageTitle"
import SignUpForm from "./signUpForm"
import { Link } from "gatsby"
import { Box } from "theme-ui"

const SignUp = ({ location }) => {
  return (
    <Wrapper>
      <Box
        sx={{
          padding: [3, 3],
          backgroundColor: "#ffdddd",
          borderBottom: "1px solid #f44336",
          color: "#f44336",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Area riservata ai soli docenti.
      </Box>

      <Container>
        <Row>
          <Col md={12}>
            <PageTitle>
              <Display>Registrati</Display>
              <p>
                Sei già registrato?{" "}
                <Link to="/area-docenti/accedi/">Accedi</Link>
              </p>
            </PageTitle>
          </Col>
        </Row>
        <Row justify="center">
          <Col sm={12} md={9} lg={6}>
            <Box
              sx={{
                padding: [2, 3],
                backgroundColor: "#fafafa",
                fontSize: [1],
                p: {
                  mb: [2],
                  ":last-of-type": {
                    mb: [0],
                  },
                },
              }}
            >
              <p>La registrazione è consentita ai soli docenti.</p>
              <p>
                La richiesta di registrazione sarà verificata dalla rete di
                propaganda.
              </p>
            </Box>
            <SignUpForm book={location.state ? location.state.book : null} />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 0rem;
  margin-bottom: 6rem;
`

export default SignUp
